import React from "react";
import Image from "gatsby-image";
import { P } from "../Type";
import { BespokeStepProps } from "../../proptypes";
import styles from "./style.module.scss";

const Step = ({ title, description, stepNumber, image }) => (
  <div className={styles.timelineItm}>
    <div className={styles.timelineIcon}>
      {image ? (
        <Image {...image} />
      ) : (
        <div className={styles.timelineIcon}>{stepNumber}</div>
      )}
    </div>
    <div className={styles.timelineKontentKontainer}>
      <div className={styles.timelineKontent}>
        <h3>{title}</h3>
        <P>{description.description}</P>
      </div>
    </div>
  </div>
);

Step.propTypes = {
  ...BespokeStepProps
};

export default Step;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { BespokeStepProps } from "../../proptypes";
import styles from "./style.module.scss";
import Step from "./Step";

const ProcessSteps = ({ steps }) => (
  <Fragment>
    <div className={styles.timeline}>
      {steps.map(step => (
        <Step key={step.id} {...step} />
      ))}
    </div>
  </Fragment>
);

ProcessSteps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      ...BespokeStepProps
    })
  )
};

ProcessSteps.defaultProps = {
  steps: []
};

export default ProcessSteps;

import React from "react";
import { Video, Transformation } from "cloudinary-react";
import SEO from "../../components/SEO";
import ScrollDecorator from "../../components/ScollDecorator";
import ProcessSteps from "../../components/ProcessSteps";
import { Section, Container } from "../../components/LayoutUtils";
import Layout from "../../layouts/index";
import { H1, P } from "../../components/Type";
import styles from "./styles.module.scss";

const ProcessPage = () => (
  <Layout>
    <SEO
      title="The Bespoke Process"
      description="Bespoke tailoring can seem complicated, here's all the steps in the process from commission to collection."
    />
    <Container>
      <Section>
        <H1>The bespoke process</H1>
        <P>
          It takes a while, and a few steps before one of our handmade garments
          is ready for you to collect. Here&apos;s a guide to help you know what
          to expect.
        </P>
      </Section>
      <Section>
        <ScrollDecorator scrollLimit={350} lockedClass={styles.locked}>
          <div className="row">
            <div className="col-12 col-md-7">
              <ProcessSteps />
            </div>
            <div className="col-12 col-md-5">
              <Video
                className={styles.video}
                cloudName="dknzpw4ti"
                publicId="IMG_2458"
                secure
                muted
                loop
                autoPlay
              >
                <Transformation quality="70" />
              </Video>
            </div>
          </div>
        </ScrollDecorator>
      </Section>
    </Container>
  </Layout>
);

export default ProcessPage;

import React from "react";
import { map, pipe, get } from "lodash/fp";
import { StaticQuery, graphql } from "gatsby";
import ProcessSteps from "./ProcessSteps";

const query = graphql`
  {
    steps: allContentfulBespokeProcessStep(
      sort: { fields: [stepNumber], order: ASC }
    ) {
      edges {
        node {
          id
          title
          stepNumber
          description {
            id
            description
          }
          image {
            id
            fixed(quality: 100, width: 100, height: 100) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`;

const render = props => data => (
  <ProcessSteps
    steps={pipe(
      get("steps.edges"),
      map("node")
    )(data)}
    {...props}
  />
);

const ProcessStepsContainer = props => (
  <StaticQuery query={query} render={render(props)} />
);

export default ProcessStepsContainer;
